import React from 'react';
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/common/layout/seo"
import { Flex } from "../components/global"
import PageLayout from "../components/common/layout/pageLayout";

const About = () => {
  const data = useStaticQuery(graphql`
  query {
    file(sourceInstanceName: { eq: "product" }, name: { eq: "founder" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return (
    <PageLayout>
      <SEO title="About" />
      <h1>About Hedera Tech</h1>
      <p>Hedera Learning founded by Rakesh, aims to solve diversified and complicated problem of distributing knowledge to needed people.</p>
      <h3>Meet the Team</h3>
      <Flex>
        <ProfileContainer>
          <StyledImage fluid={data.file.childImageSharp.fluid} alt="Founder" />
        </ProfileContainer>

        <div>
          <h4>Rakesh Kalyankar</h4>
          <p>Ex GE, Broadcom, earlier worked from Microwave Communication to 3G, LTE. Later worked as a App developer consultant for a IoT smart Helmet project. Developed app for IoT projects like Smart Light, Mesh Pace clock. Now full time working to bring Hedera Learning to completion</p>
        </div>
      </Flex>

    </PageLayout>
  );
}

export default About;

const StyledImage = styled(Img)`
  width: 200px;
  height: 200px;
  border-radius: 200px;
`
const ProfileContainer = styled.div`
  display: flex;
  justify-content: center
`
